<template>
  <z-card-modal
    data-cy="sent-notification-modal"
    :title="$t('reservation.sent_notification_modal_title')"
    :opened="opened"
    persistent
    width="475px"
  >
    <template #content>
      <div class="body-modal-container">
        <z-icon color="var(--lightgreen-600)" size="100px" class="mt-3 mb-3">
          $check_circle_outline_rounded
        </z-icon>
        <p class="desktop-text body-text text-center hidden-sm-and-down">
          {{ $t("reservation.sent_notification_desktop_text") }}
        </p>
        <p
          class="mobile-text text-center body-text hidden-md-and-up"
          v-html="$t('reservation.sent_notification_mobile_text')"
        ></p>
      </div>
      <div class="btn-container">
        <z-btn
          data-cy="sent-notification-ok"
          :text="$t('reservation.btn_ok')"
          primary
          @click="$emit('click:close-sent-modal')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.body-modal-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.body-modal-container img {
  margin: 15px 0;
  width: 100px;
}

.btn-container {
  display: flex;
  justify-content: center;
}

.btn-container button {
  width: 100%;
  margin-top: 15px;
}

@media screen and (min-width: 960px) {
  .btn-container {
    display: flex;
    justify-content: flex-end;
  }

  .btn-container button {
    width: 130px;
    margin-top: 15px;
  }
}
</style>
