<template>
  <div class="main">
    <h3 class="page-title mt-4">
      {{ $t("reservation.notify_guest") }}
    </h3>
    <p
      class="body2 mt-3"
      v-html="$t('reservation.guest_notification_main_text')"
    ></p>
    <div class="card-scrollbar my-3">
      <z-card
        v-for="(guest, index) in guestsForNotificate"
        :key="guest.id"
        :title="guest.fullName"
        class="mb-4"
        width="100%"
      >
        <template #content>
          <section class="guest-container mt-3">
            <z-input
              data-cy="input-email"
              :label="$t('reservation.email_input_label')"
              :value="guest.email"
              @input="
                changeGuestsForNotificate({ [`[${index}].email`]: $event })
              "
            />
            <z-phone-input
              data-cy="input-phone"
              is-mobile
              :label="$t('reservation.phone_input_label')"
              :current-country="guest.currentCountry"
              :value="guest.mobile"
              :countries="countries"
              @change:country="
                changeGuestsForNotificate({
                  [`[${index}].currentCountry`]: $event,
                })
              "
              @input="
                changeGuestsForNotificate({ [`[${index}].mobile`]: $event })
              "
            />
          </section>
        </template>
      </z-card>
    </div>
    <section class="btn-container">
      <z-btn
        data-cy="send-notification-btn"
        primary
        :is-loading="isLoading"
        :text="$t('reservation.notify_guest')"
        @click="onClickSendNotification"
      />
      <z-btn
        :text="$t('reservation.btn_voltar')"
        @click="$router.push({ name: 'home' })"
      />
    </section>
    <sent-notification-modal
      :opened="isModalOpened.notificationSuccess"
      @click:close-sent-modal="onclickCloseSentModal"
    />
    <error-notification-modal
      :opened="isModalOpened.notificationError"
      :title="$t('reservation.send_notification_error')"
      :text="$t('reservation.send_notification_error_messager')"
      @click:close="isModalOpened.notificationError = false"
    />
  </div>
</template>

<script>
import countries from "@zoox-ui/data/countries.json"
import { reactive } from "vue"
import { useRouter } from "vue-router/composables"
import {
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { useStoreAction } from "@/composables"

import SentNotificationModal from "./components/SentNotificationModal.vue"

/**
 * Tela utilizada somente na visualização mobile
 */
export default {
  components: { SentNotificationModal },
  setup() {
    const isModalOpened = reactive({
      notificationSuccess: false,
      notificationError: false,
    })

    const { guestsForNotificate } = useNamespacedState("reservation", [
      "guestsForNotificate",
    ])
    const { changeGuestsForNotificate } = useNamespacedMutations(
      "reservation",
      ["changeGuestsForNotificate"]
    )

    const router = useRouter()

    const {
      sendNotificationToGuest,
      hasError: notificationError,
      isLoading,
    } = useStoreAction("reservation", "sendNotificationToGuest")

    const onClickSendNotification = async () => {
      await sendNotificationToGuest()

      if (notificationError.value) {
        isModalOpened.notificationError = true

        return
      }

      isModalOpened.notificationSuccess = true
    }

    const onclickCloseSentModal = () => {
      isModalOpened.notificationSuccess = false
      router.push({ name: "home" })
    }

    return {
      isModalOpened,
      countries,
      guestsForNotificate,
      changeGuestsForNotificate,

      isLoading,

      onClickSendNotification,
      onclickCloseSentModal,
    }
  },
}
</script>

<style lang="scss" scoped>
.card-scrollbar {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.btn-container {
  display: flex;
  flex-direction: column;
}

.btn-container button {
  margin-bottom: 15px;
}
</style>
